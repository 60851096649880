
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { cloneDeep } from 'lodash';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import LynusPopup from '@/ui/components/components/LynusPopup.vue';
import { initFlatsAddresses, hasArrayDuplicateValue, filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import { counterLimits } from '@/ui/components/wizards/tenantWizard/wizardSettings/limits';
import { IMeasurements } from '@/types/measurements.types';
import { IDevice } from '@/types/devices.types';
import { IMember } from '@/types/members.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IProject } from '@/types/project.types';
import { plcVersionDate, chargingStationV2Feature, tenantAcBatteryUpdate, tenantUpdateWagoCounters } from '@/utils/versionManagementUtils';
import { ICountersObject, IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';
import { convertArrayToBit, convertBinaryNumberToDecimal, convertBitToArray, convertDecimalNumberToBinary } from '@/utils/utilsFunctions';
import { MQTTFeedbackError } from '@/store/modules/installationWizard/MQTTFeedbackError';
import { IReportBox } from '@/types/app.types';
import { IWizardLoadingState } from '@/types/wizards/wizard.general.types';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';
import AddUserToChargeStationWindow from '@/ui/components/wizards/tenantWizard/components/AddUserToChargeStationWindow.vue';
import { IRoom } from '@/types/rooms.types';
import { getChargeStationNameByValue } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { defaultTimeoutRepotMessages } from '@/utils/constants';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
    LynusPopup,
    AddUserToChargeStationWindow,
  },
})
export default class Electricity extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('tenantWizard/isAutoMappingEnabled') isAutoMappingEnabled!: boolean;
  @Getter('variables/variablesForComboBox') noAutomappingVaribles!: [];
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('members/members') members!: IMember[];
  @Getter('projects/project') project!: IProject;
  @Getter('tenantWizard/hasLynusInverter') hasLynusInverter!: boolean;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('tenantWizard/overallProductionMeasurement') getOverallProductionMeasurement!: string;
  @Mutation('installationWizard/setLoadingState') setLoadingState!: (payload: IWizardLoadingState) => void;
  @Mutation('tenantWizard/setOverallProductionMeasurement') setOverallProductionMeasurement!: (payload: string) => void;
  @Mutation('installationWizard/resetLoadingState') resetLoadingState!: () => void;
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('installationWizard/sendVariables') sendVariables!: (variablesToSend: IMQTTVariable[]) => Promise<void>;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('members/updateMemberCollections') updateMemberCollections!: (
    { project_id, member, collectionsList }: { project_id: string; member: IMember; collectionsList: string[] }
  ) => Promise<void>;

  localCounterLimits = counterLimits;
  valid = false;
  sideCosts = 0;
  flatsConfigList: IFlatsConfig[] = [];
  flatsFullAddress: Record<string, string> = {};
  overallEnergyCounterIdArray: any = [];
  overallproductionCounterIdArray: any= [];
  overallAcBatteryCounterIdArray: any = [];
  gridCounterId= '';
  gridProductionCounter='';
  chargeStationValidationArray: boolean[] = [];
  chargeStationsValid = true;

  overallAcBatteryCounterNumberList: any[] = [];
  flatsFullAddressNumberList: any[] = [];
  overallEnergyCounterNumberList: any[] = [];
  // production counter
  overallPVCounterNumberList: any[] = [];
  // consumption counter
  gridCounterNumber = null;

  activateAcBattery = false;
  variablesToSend: IMQTTVariable[] = [];

  overallProductionMeasurement = '';

  /**
   * Disables checkbox of a specific flat if the flat has a charging station and the counter is set via the counter id field
   * also marks hasChargingStation as false if the counter is set
   */
  disableCheckbox(flatsConfig: IFlatsConfig, index: number) {
    if (this.isAutoMappingEnabled) {
      const check = flatsConfig.counters.energyCounter.some((counter: ICountersObject) => Number.isNaN(parseInt(counter.id, 10)));
      if (check) {
        this.flatsConfigList[index].hasChargingStation = false;
      }
      return check;
    } else {
      const check = flatsConfig.counters.energyCounter.some((counter: ICountersObject) => counter.id.includes('prgCS.lrCounterCS'));
      if (check) {
        this.flatsConfigList[index].hasChargingStation = false;
      }
      return check;
    }
  }

  get checkLynusInverterChanges() {
    // if feature is not enabled we return true
    if (!this.showAcBatteryFeature) return true;
    // if feature is enabled and there is no lynus inverter we return true
    if (!this.hasLynusInverter) return true;

    // if feature is enabled and there is a lynus inverter we check if the ac battery is activated and a overallProductionMeasurement is set
    // then we return false
    if (this.activateAcBattery && this.overallProductionMeasurement !== '') return false;

    return true;
  }

  get overallProductionMeasurementItems() {
    if (this.isAutoMappingEnabled) {
      // map all production counters
      return this.overallPVCounterNumberList.map((counter: any) => {
        return {
          text: counter,
          value: counter,
        };
      }).filter((counter: any) => counter.text !== 'prgEnergy.lrCounterProdTotal' && counter.text !== 'prgEM.lrCounterProdTotal_2');
    } else {
      // map production id's
      return this.overallproductionCounterIdArray.map((counter: any) => {
        return {
          text: counter,
          value: counter,
        };
      }).filter((counter: any) => counter.text !== 'prgEnergy.lrCounterProdTotal' && counter.text !== 'prgEM.lrCounterProdTotal_2');
    }
  }

  get showChargeStationV2Feature() {
    return plcVersionDate(this.project).getTime() > chargingStationV2Feature.getTime();
  }

  get showAcBatteryFeature() {
    return plcVersionDate(this.project).getTime() > tenantAcBatteryUpdate.getTime() && this.hasLynusInverter;
  }

  valueNotAllowedCheck(value: any, useFlatMappings: boolean) {
    if (!this.isAutoMappingEnabled) {
      return true;
    }
    const number = parseInt(value, 10);
    if (useFlatMappings && this.showChargeStationV2Feature) {
      const valueToCheck = Number.isNaN(number) ? value : number;
      if (!this.variablesForComboboxFlats.includes(valueToCheck)) {
        return this.$t('tenantWizard.valueNotAllowed');
      }
    } else {
      if (!this.variablesForCombobox.includes(number)) {
        return this.$t('tenantWizard.valueNotAllowed');
      }
    }
    return true;
  }

  /**
   * Returns array of electric charging station devices from all devices
   */
  get electricChargingStationV2Devices() {
    return this.allDevices.filter((device: IDevice) => device.data.type === 'ElectricChargingStationV2');
  }

  /**
   * Returns array of all selected users for charging stations
   */
  get allSelectedUsers() {
    const getAllSelectedUsers: any = [];
    this.electricChargingStationV2Devices.forEach((device: IDevice) => {
      device.data.meta.selectedUsers.forEach((user: any) => {
        if (getAllSelectedUsers.indexOf(user) === -1) {
          getAllSelectedUsers.push(user);
        }
      });
    });
    return getAllSelectedUsers ?? [];
  }

  handleAcBatteryCheckbox() {
    if (!this.activateAcBattery) {
      if (this.isAutoMappingEnabled) {
        this.overallAcBatteryCounterNumberList = [];
      } else {
        this.overallAcBatteryCounterIdArray = [];
      }
    } else {
      if (this.isAutoMappingEnabled) {
        this.overallAcBatteryCounterNumberList.push(null);
      } else {
        this.overallAcBatteryCounterIdArray.push('');
      }
    }

    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  /**
   * Checks 'prgCS.wWebasto_Unite' variable and returns an array of indices of webasto unite charge stations
   * These can be selected as flat specific counters
   */
  get webastoUniteIndex() {
    const decimalValue = typeof this.measurements['prgCS.wWebasto_Unite'] === 'number' ?
      this.measurements['prgCS.wWebasto_Unite'] : parseInt(this.measurements['prgCS.wWebasto_Unite'], 10);
    const binaryValue = convertDecimalNumberToBinary(decimalValue ?? 0);
    return convertBitToArray(binaryValue.toString());
  }

  get chargeStationSelectItems() {
    const supportedChargeStationTypes = ['Webasto11', 'Webasto22'];
    return this.electricChargingStationV2Devices.map((device: IDevice) => {
      let indexOfDevice = device.data.mappings.OutputField_actualValue.match(/\d+/);
      indexOfDevice = indexOfDevice ? parseInt(indexOfDevice[0], 10) : -1;
      // if no index is found we return undefined
      if (indexOfDevice === -1) {
        return undefined;
      }
      const name = `${device.name}: ${getChargeStationNameByValue(device.data.meta.systemType)}`;
      // check if the device is a supported charge station type
      if (supportedChargeStationTypes.includes(device.data.meta.systemType)) {
        // if the device is a webasto charge station we check if the device is a webasto charge station
        if (device.data.meta.systemType.includes('Webasto')) {
          // if the prgCS.byTypeECS is set to 1 (this means the device is a webasto unite charge station),
          // or for the new version if it is set via the 'prgCS.wWebasto_Unite' variable
          if (this.measurements[`prgCS.byTypeECS_${indexOfDevice}`] === 1 || this.webastoUniteIndex.includes(indexOfDevice)) {
            // only use unite charge stations as others are not supported for this feature
            return {
              text: name,
              value: `prgCS.lrCounterCS_${indexOfDevice}`,
            };
          } else {
            return undefined;
          }
        } else {
          // if in future some other charge station types are supported we just add them here
          return {
            text: name,
            value: `prgCS.lrCounterCS_${indexOfDevice}`,
          };
        }
      } else {
        return undefined;
      }
    }).filter((device: any) => device !== undefined);
  }

  /**
   * Add compatible charge stations to the combobox for selection as a flat specific counter
   */
  get variablesForComboboxFlats() {
    // if automapping is disabled we just return normal meassurement variables
    if (!this.isAutoMappingEnabled) {
      return this.noAutomappingVaribles;
    }
    const chargeStationArray: string[] = [];
    const numberArray = Array.from({ length: 255 }, (_, index) => index + 1);
    this.chargeStationSelectItems.forEach((item: any) => {
      chargeStationArray.push(item.text);
    });
    return [...chargeStationArray, ...numberArray];
  }

  /**
   * Update device and trigger fetchDevices
   */
  async handleUpdateDevice(obj: { chargeStation: IDevice; index: number }) {
    await this.updateDevice({ device: obj.chargeStation, skipReport: false });
    await this.fetchDevices(this.$route.params.id);
    this.handleCheckboxChange(obj.index);
  }

  /**
   * Update chargeStationValidationArray and chargeStationsValid
   */
  handleCheckboxChange(flatIndex: number) {
    const { mail } = this.flatsConfigList[flatIndex];
    // check if checkbox is checked
    if (this.flatsConfigList[flatIndex].hasChargingStation) {
      // if checkbox is checked we check if the user selected for the charging station is valid
      if (this.getInfoText(mail).valid === false) {
        this.chargeStationValidationArray[flatIndex] = false;
      } else {
        this.chargeStationValidationArray[flatIndex] = true;
      }
    } else {
      // if checkbox is unchecked we set the validation to true
      this.chargeStationValidationArray[flatIndex] = true;
    }
    this.chargeStationsValid = this.chargeStationValidationArray.every((valid: boolean) => valid);
    this.flatsConfigList = cloneDeep(this.flatsConfigList);
  }

  getInfoText(mail: string) {
    const id = this.getMemberIdByMail(mail);
    const member = this.members.find((member: IMember) => member.id === id);
    const memberName = `${member?.first_name} ${member?.last_name}`;

    if (id === undefined) {
      // if id is undefined the user is not a member of the project
      return {
        text: this.$t('tenantWizard.electricity.userNotInProject'),
        icon: 'mdi-close',
        color: 'red',
        valid: true,
        errorType: 'undefined',
        memberId: id,
      };
    }

    if (this.allSelectedUsers.includes(id)) {
      if (this.checkIfMemberIsAdmin(id)) {
        // for admin users we show a information text that the user is an admin
        return {
          text: this.$t('tenantWizard.electricity.userIsAdmin', { user: memberName }),
          icon: 'info_outlined',
          color: 'orange',
          valid: true,
          memberId: id,
        };
      } else {
        // for normal users we show a check icon
        return {
          text: undefined,
          icon: 'fa-check',
          color: 'green',
          valid: true,
          memberId: id,
        };
      }
    } else {
      // if the user is not selected for a charging station we show a close icon
      return {
        text: this.$t('tenantWizard.electricity.userNotAssignedToChargingStation', { user: memberName }),
        icon: 'mdi-close',
        color: 'red',
        valid: true,
        errorType: 'notAssigned',
        memberId: id,
      };
    }
  }

  /**
   * Returns the member id by mail
   */
  getMemberIdByMail(id: string) {
    return this.members.find((member: IMember) => member.email === id)?.id;
  }

  /**
   * Checks if member is admin
   */
  checkIfMemberIsAdmin(id: string) {
    const member = this.members.find((member: IMember) => member.id === id);
    return member?.role === UserRoleCode.admin;
  }

  /**
   * Returns the amount of consumption counters
   */
  get electricityCounterAmount() {
    let otherCounterNumber = 0;
    let flatCounterNumber = 0;

    if (this.isAutoMappingEnabled) {
      otherCounterNumber = this.overallEnergyCounterNumberList.length + this.overallPVCounterNumberList.length + this.overallAcBatteryCounterNumberList.length + 1;
    } else {
      otherCounterNumber = this.overallEnergyCounterIdArray.length + this.overallproductionCounterIdArray.length + 1;
    }

    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flatCounterNumber += flat.counters.energyCounter.length;
    });
    return otherCounterNumber + flatCounterNumber;
  }

  get hasProductionCounterTotal(): boolean {
    return Object.keys(this.measurements).includes('prgEnergy.lrCounterProdTotal') || Object.keys(this.measurements).includes('prgEM.lrCounterProdTotal_2');
  }

  get variablesForCombobox() {
    // if automapping is disabled we just return normal meassurement variables
    if (!this.isAutoMappingEnabled) {
      return this.noAutomappingVaribles;
    }
    return Array.from({ length: 255 }, (_, index) => index + 1);
  }

  get hasDuplicateValue(): boolean | string {
    // will contain all numbers that are set either inside overall counters, grid counter or grid production counter or inside flats counters
    let array = [];
    if (!this.isAutoMappingEnabled) {
      array = [
        this.overallEnergyCounterIdArray.map((value: { id: string; name: string}) => value.id),
        this.overallproductionCounterIdArray,
        this.overallAcBatteryCounterNumberList,
        this.gridCounterId,
        this.gridProductionCounter,
      ].flat();
    } else {
      array = [
        this.overallEnergyCounterNumberList.map((value: { id: string; name: string}) => value.id),
        this.overallPVCounterNumberList,
        this.overallAcBatteryCounterNumberList,
        this.gridCounterNumber,
      ].flat();
    }

    // add flats counters
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.energyCounter.forEach((counter: any) => {
        array.push(counter.id);
      });
    });

    return hasArrayDuplicateValue(array, 'tenantWizard.hasDuplicateValue');
  }

  get hasDuplicateName(): boolean | string {
    // will contain all numbers that are set either inside overall counters, grid counter or grid production counter or inside flats counters
    let array = [];
    if (this.isAutoMappingEnabled) {
      array = [this.overallEnergyCounterNumberList.map((value: { id: string; name: string}) => value.name), this.overallPVCounterNumberList].flat();
    } else {
      array = [this.overallEnergyCounterIdArray.map((value: { id: string; name: string}) => value.name), this.overallproductionCounterIdArray].flat();
    }
    // add grid counter and grid production counter
    array.push(this.gridCounterNumber as any);
    // add flats counters
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.energyCounter.forEach((counter: any) => {
        array.push(counter.name);
      });
    });

    return hasArrayDuplicateValue(array, 'tenantWizard.hasDuplicateName') ?? true;
  }

  created() {
    this.fetchVariables(this.$route.params.id);
    this.flatsConfigList = this.tenantState.tenant.general.flats.flatConfigurations;
    if (this.flatsConfigList[0].counters.energyCounter.length === 0) {
      this.flatsConfigList.forEach((flat: IFlatsConfig) => {
        flat.counters.energyCounter.push({ id: '', name: '' });
      });
    }
    this.overallEnergyCounterIdArray = this.tenantState.tenant.general.overallCounters.energyCounter;
    this.overallproductionCounterIdArray = this.tenantState.tenant.general.productionCounters;
    if (this.overallproductionCounterIdArray.length === 0 && !this.hasLynusInverter && this.showAcBatteryFeature) {
      // if we dont have a lynus inverter we need to set the overall production counter to the default value
      this.overallproductionCounterIdArray.push('');
    }
    this.gridCounterId = this.tenantState.tenant.general.gridCounter;
    this.gridProductionCounter = this.tenantState.tenant.general.gridProductionCounter ?? '';

    if (this.showChargeStationV2Feature) {
      this.flatsConfigList.forEach((flat: IFlatsConfig, flatIndex: number) => {
        if (flat.hasChargingStation) {
          // if checkbox is checked we check if the user selected for the charging station is valid
          if (this.getInfoText(flat.mail).valid === false) {
            this.chargeStationValidationArray.push(true);
            this.chargeStationValidationArray[flatIndex] = false;
          } else {
            this.chargeStationValidationArray.push(true);
            this.chargeStationValidationArray[flatIndex] = true;
          }
        } else {
          // if checkbox is unchecked we set the validation to true
          flat.hasChargingStation = false;
          this.chargeStationValidationArray.push(true);
          this.chargeStationValidationArray[flatIndex] = true;
        }
      });
    }

    if (this.isAutoMappingEnabled) {
      this.fillNumbers();
    }
    if (plcVersionDate(this.project).getTime() > tenantAcBatteryUpdate.getTime()) {
      // if ac battery is set but we dont have a lynus inverter we need to reset the ac battery part
      if (!this.hasLynusInverter) {
        this.activateAcBattery = false;
        this.overallAcBatteryCounterNumberList = [];
        this.overallAcBatteryCounterIdArray = [];
        // if we dont have a lynus inverter we remove all internal counters from the production counters
        if (!this.hasLynusInverter) {
          // clear also all internal counters from the production counters
          if (this.isAutoMappingEnabled) {
            this.overallPVCounterNumberList = this.overallPVCounterNumberList.filter((counter: any) => counter !== 'prgEM.lrCounterProdTotal_2' && counter !== 'prgEnergy.lrCounterProdTotal');
            if (this.overallPVCounterNumberList.length === 0) {
              this.overallPVCounterNumberList.push(null);
            }
          } else {
            this.overallproductionCounterIdArray = this.overallproductionCounterIdArray.filter((counter: any) => counter !== 'prgEM.lrCounterProdTotal_2' && counter !== 'prgEnergy.lrCounterProdTotal');
            if (this.overallproductionCounterIdArray.length === 0) {
              this.overallproductionCounterIdArray.push('');
            }
          }
        }
      } else {
        this.initAcBatteryField();
      }
      // save the overall production measurement
      if (this.isAutoMappingEnabled) {
        this.overallProductionMeasurement = filterNumberFromString(this.getOverallProductionMeasurement);
      } else {
        this.overallProductionMeasurement = this.getOverallProductionMeasurement;
      }
    }
  }

  initAcBatteryField() {
    // if set saved value will be the decimal equivalent of the bit set with the ac battery numbers
    let savedValue = this.measurements['prgEM.lwPosOfACBattery'];

    // if saved value is undefined we do not need to do anything
    if (!savedValue) return;

    if (typeof savedValue === 'string') {
      savedValue = parseInt(savedValue, 10);
    }
    // if saved value is 0 we do not need to do anything
    if (savedValue === 0) return;

    // convert the decimal number to binary and then to an array of numbers used for the ac battery mapping
    const binaryNumber = convertDecimalNumberToBinary(savedValue).toString();
    const acBatteryNumbers = convertBitToArray(binaryNumber);
    if (this.isAutoMappingEnabled) {
      // add ac battery number
      this.activateAcBattery = true;
      this.overallAcBatteryCounterNumberList = acBatteryNumbers;
    } else {
      // add variable mapping
      this.activateAcBattery = true;
      this.overallAcBatteryCounterIdArray = acBatteryNumbers.map((number: number) => `prgEM.lrCounter_E_P_${number}`);
    }
  }

  fillNumbers() {
    // overall part
    this.overallEnergyCounterNumberList = this.overallEnergyCounterIdArray.map((counter: any) => {
      return { id: filterNumberFromString(counter.id), name: counter.name };
    });
    // grid counter address
    const numberGridCounter: any = filterNumberFromString(this.gridCounterId);
    this.gridCounterNumber = numberGridCounter;
    // grid production counter address
    const numberGridProductionCounter: any = filterNumberFromString(this.gridProductionCounter);

    // pv counter part
    this.overallPVCounterNumberList = this.overallproductionCounterIdArray.map((counter: any) => {
      if (counter === 'prgEnergy.lrCounterProdTotal' || counter === 'prgEM.lrCounterProdTotal_2') {
        return counter;
      }
      return filterNumberFromString(counter);
    });

    // flats part
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.energyCounter.forEach((counter: any) => {
        if (this.showChargeStationV2Feature) {
          if (counter.id.includes('prgCS.lrCounterCS')) {
            // search in chargeStationSelectItems and map internal counter from charge station to variable
            counter.id = this.chargeStationSelectItems.find((item: any) => item.value === counter.id)?.text;
          } else {
            // if the number is not a charge station number we map it to the default variable
            counter.id = filterNumberFromString(counter.id);
          }
        } else {
          counter.id = filterNumberFromString(counter.id);
        }
      });
    });
  }

  removeCounterFromFlat(flatIndex: number, counterIndex: number) {
    this.flatsConfigList[flatIndex].counters.energyCounter.splice(counterIndex, 1);
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  addCounterToFlat(flatIndex: number) {
    this.flatsConfigList[flatIndex].counters.energyCounter.push({ id: '', name: '' });
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  removeItem(index: number, type: string) {
    if (type === 'energy') {
      if (this.isAutoMappingEnabled) {
        this.overallEnergyCounterNumberList.splice(index, 1);
      } else {
        this.overallEnergyCounterIdArray.splice(index, 1);
      }
    } else if (type === 'pv') {
      let value = '';
      if (this.isAutoMappingEnabled) {
        value = this.overallPVCounterNumberList[index];
        this.overallPVCounterNumberList.splice(index, 1);
      } else {
        value = this.overallproductionCounterIdArray[index];
        this.overallproductionCounterIdArray.splice(index, 1);
      }
      if (this.overallProductionMeasurement === value) {
        this.overallProductionMeasurement = '';
      }
    } else if (type === 'acBattery') {
      if (this.isAutoMappingEnabled) {
        this.overallAcBatteryCounterNumberList.splice(index, 1);
      } else {
        this.overallAcBatteryCounterIdArray.splice(index, 1);
      }
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  addItem(type: string) {
    if (type === 'energy') {
      if (this.isAutoMappingEnabled) {
        this.overallEnergyCounterNumberList.push({ id: '', name: '' });
      } else {
        this.overallEnergyCounterIdArray.push({ id: '', name: '' });
      }
    } else if (type === 'pv') {
      if (this.isAutoMappingEnabled) {
        this.overallPVCounterNumberList.push(null);
      } else {
        this.overallproductionCounterIdArray.push('');
      }
    } else if (type === 'acBattery') {
      if (this.isAutoMappingEnabled) {
        this.overallAcBatteryCounterNumberList.push(null);
      } else {
        this.overallAcBatteryCounterIdArray.push('');
      }
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  async mounted() {
    this.flatsFullAddress = initFlatsAddresses(this.flatsConfigList);

    // if tenant wizard was not done already, fill production counter
    if (!this.tenantState.wasTenantDone) await this.fillProductionCounter();

    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  async fillProductionCounter() {
    await this.fetchMeasurements(this.$route.params.id);
    // check if measurements or production counters include variable prgEnergy.lrCounterProdTotal
    if (!this.overallproductionCounterIdArray.includes('prgEnergy.lrCounterProdTotal') && Object.keys(this.measurements).includes('prgEnergy.lrCounterProdTotal')) {
      if (this.isAutoMappingEnabled) {
        this.overallPVCounterNumberList.push('prgEnergy.lrCounterProdTotal');
      } else {
        this.overallproductionCounterIdArray.push('prgEnergy.lrCounterProdTotal');
      }
    }
  }

  handleBack() {
    // if we are going back to the automapping step
    // and we are in the automapping mode we need to convert the numbers to the variable names
    if (this.isAutoMappingEnabled) {
      this.convertNumbersToVariables();
    }
    this.handleDecrement();
  }

  async onNext() {
    let returnValue = true;
    if (plcVersionDate(this.project).getTime() > tenantAcBatteryUpdate.getTime()) {
      returnValue = await this.updateMeasurements();
      // set overall production measurement
      if (this.isAutoMappingEnabled) {
        if (this.overallProductionMeasurement !== '') {
          // if overall production measurement is set we set it to the variable name
          this.setOverallProductionMeasurement(`prgEM.lrCounter_E_P_${this.overallProductionMeasurement}`);
        } else {
          // if overall production measurement is empty we set it to an empty string
          this.setOverallProductionMeasurement('');
        }
      } else {
        this.setOverallProductionMeasurement(this.overallProductionMeasurement);
      }

      if (this.overallProductionMeasurement !== '') {
        // remove digital counter if total consumption counter is set
        this.clearInternalCounters();
      }
      if (!this.hasLynusInverter) {
        // remove digital counter if total consumption counter is set
        this.clearInternalCounters();
      }

      await this.updateUserAccess();
    }
    // if request was not successful we do not continue
    if (!returnValue) return;

    if (this.isAutoMappingEnabled) {
      this.convertNumbersToVariables();
    }
    this.checkLrProductionCounters();
    this.updateTenant();
    this.handleIncrement();
  }

  async updateUserAccess() {
    if (this.electricChargingStationV2Devices.length === 0) return;
    const collectionIdToCheck = this.electricChargingStationV2Devices[0].collection_id;
    // check if all users that are selected for the charging station have access to the area or are admin users
    await this.allSelectedUsers.forEach(async (selectedUserForChargeStation: string) => {
      const memberById = this.members.find((member: IMember) => member.id === selectedUserForChargeStation);
      if (!memberById) return;
      if (memberById.role !== UserRoleCode.admin) {
        const collectionsForUser = memberById.collections;
        if (!collectionsForUser) return;
        if (!collectionsForUser.includes(collectionIdToCheck)) {
          // if user does not have access to the collection we add the collection to the user
          collectionsForUser.push(collectionIdToCheck);
          if (!this.project.id) return;
          await this.updateMemberCollections({
            project_id: this.project.id,
            member: memberById,
            collectionsList: collectionsForUser,
          });
        }
      }
    });
  }

  async updateMeasurements() {
    await this.fetchMeasurements(this.$route.params.id);
    let bit = '';
    // convert array of numbers to bit
    if (this.overallAcBatteryCounterNumberList.length === 0 && this.overallAcBatteryCounterIdArray.length === 0) {
      bit = '0';
    } else {
      if (this.isAutoMappingEnabled) {
        bit = convertArrayToBit(this.overallAcBatteryCounterNumberList);
      } else {
        const numberArray = this.overallAcBatteryCounterIdArray.map((counter: any) => parseInt(filterNumberFromString(counter), 10));
        bit = convertArrayToBit(numberArray);
      }
    }
    // convert bit to decimal number to send it to the plc
    const decimal = convertBinaryNumberToDecimal(parseInt(bit, 10));

    this.variablesToSend = [{
      variable: 'prgEM.lwPosOfACBattery',
      value: decimal,
      feedbackVariable: 'prgEM.bConfigIsInProgress',
      customExpectedValue: 1,
    }];

    // skip sending if the value is already set
    if (this.measurements['prgEM.lwPosOfACBattery'] === decimal) {
      return true;
    }

    try {
      this.setLoadingState({
        isLoading: true,
        loadingCount: 0,
        loadingTotal: this.variablesToSend.length,
      });
      await this.sendVariables(this.variablesToSend);
      this.resetLoadingState();
      return true;
    } catch (e) {
      if (e instanceof MQTTFeedbackError) {
        this.resetLoadingState();
        this.setReport({
          type: 'error',
          message: this.$t('installationWizard.mqttFeedbackErrorMessage', { variable: e.variable }),
          value: true,
          timeout: defaultTimeoutRepotMessages,
        });
      }
      this.resetLoadingState();
      return false;
    }
  }

  clearInternalCounters() {
    if (this.isAutoMappingEnabled) {
      // parse all numbers to strings
      this.overallPVCounterNumberList = this.overallPVCounterNumberList.map((value: string | number) => {
        if (typeof value === 'number') {
          return value.toString();
        }
        return value;
      });
      this.overallPVCounterNumberList = this.overallPVCounterNumberList.filter((counter: any) => !counter.includes('prgEnergy.lrCounterProdTotal') && !counter.includes('prgEM.lrCounterProdTotal_2'));
    } else {
      this.overallproductionCounterIdArray = this.overallproductionCounterIdArray.filter((counter: any) => !counter.includes('prgEnergy.lrCounterProdTotal') && !counter.includes('prgEM.lrCounterProdTotal_2'));
    }
  }

  checkLrProductionCounters() {
    if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      // if we do not have a lynus inverter we do not need to check the production counters
      if (!this.hasLynusInverter) return;
      // if we have a overall counter and a lynus inverter we do not need a digital counter
      if (this.overallProductionMeasurement !== '' && this.hasLynusInverter) return;
    }
    // if no ac battery is connected we do not need to update the production counters
    if (this.overallAcBatteryCounterNumberList.length === 0 && this.overallAcBatteryCounterNumberList.length === 0) return;
    // if prgEM.lrCounterProdTotal_2 is set we do not need to update the production counters
    if (this.overallproductionCounterIdArray.includes('prgEM.lrCounterProdTotal_2')) return;

    // if prgEM.lrCounterProdTotal_2 is not set and we have an ac battery connected we need to add the production counter
    if (this.overallproductionCounterIdArray.includes('prgEnergy.lrCounterProdTotal')) {
      const index = this.overallproductionCounterIdArray.indexOf('prgEnergy.lrCounterProdTotal');
      // replace prgEnergy.lrCounterProdTotal with prgEM.lrCounterProdTotal_2
      this.overallproductionCounterIdArray.splice(index, 1, 'prgEM.lrCounterProdTotal_2');
    } else {
      // add prgEM.lrCounterProdTotal_2 to the production counters
      this.overallproductionCounterIdArray.push('prgEM.lrCounterProdTotal_2');
    }
  }

  convertNumbersToVariables() {
    // overall Part
    this.overallEnergyCounterIdArray = this.overallEnergyCounterNumberList.map((counter: any) => {
      return { id: `prgEM.lrCounter_E_C_${counter.id}`, name: counter.name };
    });
    // grid counter address
    this.gridCounterId = `prgEM.lrCounter_E_C_${this.gridCounterNumber}`;
    // grid production counter address
    this.gridProductionCounter = `prgEM.lrCounter_E_P_${this.gridCounterNumber}`;
    // pv counter part
    this.overallproductionCounterIdArray = this.overallPVCounterNumberList.map((counter: any) => {
      if (counter === 'prgEnergy.lrCounterProdTotal' || counter === 'prgEM.lrCounterProdTotal_2') {
        return counter;
      }
      return `prgEM.lrCounter_E_P_${counter}`;
    });

    // flats part
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.energyCounter.forEach((counter: any) => {
        if (this.showChargeStationV2Feature) {
          const number = parseInt(counter.id, 10);
          if (Number.isNaN(number)) {
            // search in chargeStationSelectItems and map internal counter from charge station to variable
            counter.id = this.chargeStationSelectItems.find((item: any) => item.text === counter.id)?.value;
          } else {
            // if the number is not a charge station number we map it to the default variable
            counter.id = `prgEM.lrCounter_E_C_${counter.id}`;
          }
        } else {
          counter.id = `prgEM.lrCounter_E_C_${counter.id}`;
        }
      });
    });
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.flats.flatConfigurations = this.flatsConfigList;
    tenantCopy.general.overallCounters.energyCounter = this.overallEnergyCounterIdArray;
    tenantCopy.general.productionCounters = this.overallproductionCounterIdArray;
    tenantCopy.general.gridCounter = this.gridCounterId;
    tenantCopy.general.gridProductionCounter = this.gridProductionCounter;
    this.updateTenantObject(tenantCopy);
  }
}
