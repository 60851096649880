
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import SystemsNavigation
  from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import {
  defineInstancesGroups,
  getChartSystemInstanceName,
} from '@/ui/components/devices/components/EnergyParts/EMS/utils';
import { roundNumber } from '@/utils/utilsFunctions';
import { periodConfigurations, Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import {
  singleEnergyChartScaling,
  singleEnergyChartScalingHybrid,
  temperatureScaling,
} from '@/utils/scalingConstants';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  getCalculation,
  getExpression,
  getCalculationHouse,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/energyChartsUtils';
import { IDevice } from '@/types/devices.types';
import { ICurrentSystem, ISystemsVars } from '@/types/common.types';
import { EnergyDataByPeriodResponse } from '@/types/energyVisualisation/energyVisualisation.types';

const DEFAULT_SYSTEMS_VARS = {
  charge_station: ['power'],
  electric_heating: ['power', 'temperature'],
  heating_pump: ['power'],
  big_consumer: ['power'],
  pv: ['power'],
  chp: [
    'power', 'thermal_power_output', 'flow_temperature', 'return_temperature',
    'buffer_temperature_top', 'buffer_temperature_bottom',
  ],
  generator: ['power'],
  grid: ['power'],
  battery: ['power', 'soc'],
  house: ['power'],
};

@Component({
  components: {
    BaseChartWrapper,
    SystemsNavigation,
    InfoTooltip,
  },
})
export default class SingleSystemsView extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: '' }) mpcId!: string;
  @Prop() settingsWrapperHeight !: number;
  @Prop({ default: false }) isMpc!: boolean
  @Prop() shownSystems !: EnergyCircleType[];
  @Prop({ default: false }) showHouseConsumptionAlways !: boolean;
  @Prop({ default: false }) showScores !: boolean;
  @Prop({ default: false }) setExpressionAsMax!: boolean;
  @Prop({ default: false }) excludeHouse!: boolean;
  @Prop({ default: 9 }) numberOfSeriesPerChart!: number;
  @Prop({ default: () => DEFAULT_SYSTEMS_VARS }) systemsVars!: ISystemsVars;
  @Prop({ default: false }) isHybridSystem!: boolean;
  @Prop({ default: 'avg' }) defaultAggregation!: string;
  @Prop({ default: () => false }) showColumnChart!: boolean;
  @Prop({ default: () => ['live', 'hour'] }) navItemsToExclude!: string[];

  @Action('mpc/fetchDataByPeriodMpc') fetchDataByPeriodMpc!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;
  @Action('devices/fetchDataByPeriod') fetchDataByPeriod!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;

  reRenderKey = 0;
  currentPeriod = Periods.DAY;
  start = periodConfigurations[this.currentPeriod].period().start;
  end = periodConfigurations[this.currentPeriod].period().end;
  energyResponse: EnergyDataByPeriodResponse | null = null;
  systems: EnergyCircleType[] = [];
  currentChart: ICurrentSystem = {
    system: null,
    instances: [],
  }
  selectedGroup = 0;
  systemsUnits: any = {
    battery: {
      soc: '%',
      power: 'kW',
      energy_counter: 'kWh',
      reverse_energy_counter: 'kWh',
    },
    electric_heating: {
      power: 'kW',
      temperature: '°C',
      energy_counter: 'kWh',
    },
    chp: {
      power: 'kW',
      thermal_power_output: 'kW',
      flow_temperature: '°C',
      return_temperature: '°C',
      buffer_temperature_top: '°C',
      buffer_temperature_bottom: '°C',
      energy_counter: 'kWh',
    },
    house: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    grid: {
      energy_counter: 'kWh',
      reverse_energy_counter: 'kWh',
      power: 'kW',
    },
    generator: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    pv: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    big_consumer: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    heating_pump: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    charge_station: {
      energy_counter: 'kWh',
      power: 'kW',
    },
  }
  showHouseCalculationTooltip = false;

  /**
   * Collection of systems scaling
   */
  get systemsScaling(): any {
    return {
      battery: {
        soc: { min: 0, max: 100 },
        energy_counter: { min: 0, max: undefined },
        reverse_energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      grid: {
        power: singleEnergyChartScalingHybrid,
        energy_counter: { min: 0, max: undefined },
        reverse_energy_counter: { min: 0, max: undefined },
      },
      electric_heating: {
        temperature: { min: 0, max: 120 },
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      chp: {
        power: singleEnergyChartScaling,
        thermal_power_output: singleEnergyChartScaling,
        flow_temperature: temperatureScaling,
        return_temperature: temperatureScaling,
        buffer_temperature_top: temperatureScaling,
        buffer_temperature_bottom: temperatureScaling,
        energy_counter: { min: 0, max: undefined },
      },
      house: {
        power: singleEnergyChartScaling,
        reverse_energy_counter: { min: 0, max: undefined },
        energy_counter: { min: 0, max: undefined },
      },
      generator: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      pv: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      big_consumer: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      heating_pump: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      charge_station: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
    };
  }
  scores: Record<string, number | null> = {};
  scoresForDataExport: Record<string, {value: number; unit: string}> = {};

  getSplitter(system: EnergyCircleType) {
    switch (system) {
      case EnergyCircleType.battery:
        return 4;
      case EnergyCircleType.grid:
        return 4;
      case EnergyCircleType.chp:
        return 1;
      default:
        return this.numberOfSeriesPerChart;
    }
  }

  getSystemsExpressionWrappers(system: any) {
    switch (system) {
      case EnergyCircleType.house: return (exp: string) => `max(${exp}, 0)`;
      default: return undefined;
    }
  }

  /**
   * Creates array of systems groups and system instances.
   * @return {array} list of system groups. Example: ['battery', [['battery1', 'battery2']]]
   */
  get systemSchema() {
    const filteredSystems = Object.entries(this.getSystems(this.deviceData))
      .filter((system: any) => this.systems.some((el: any) => el === system[0] && system[1]?.count));
    const mappedSystems = filteredSystems.map((el: any) => {
      return defineInstancesGroups(el, this.getSplitter(el[0]), 'power', this.getSystems(this.deviceData));
    }).filter((system: any) => system?.[1].length);
    return mappedSystems;
  }
  /**
   * House system options data
   */
  get houseConsumptionPowerVariablesList() {
    return Object.values(this.getSystemComponents(this.deviceData, EnergyCircleType.house))
      .map((instance: any) => instance.power)
      .filter((variable: string) => variable);
  }
  get houseConsumptionPowerVariablesExpression() {
    return this.houseConsumptionPowerVariablesList.join('+');
  }
  get houseChartOptions() {
    return {
      calculation: {
        aggregations: [...this.houseConsumptionPowerVariablesList, ...this.externalMeasurementsSystemsVariablesList].fill('avg'),
        expression: getExpression({
          system: EnergyCircleType.house,
          variableValue: this.houseConsumptionPowerVariablesExpression,
          expressionWrapperFn: this.getSystemsExpressionWrappers(EnergyCircleType.house),
          externalMeasurementsList: this.externalMeasurementsSystemsVariablesList,
        }),
      },
      name: 'House Consumption',
      scaling: singleEnergyChartScaling,
      seriesType: 'Calculation',
      type: this.showColumnChart ? 'column' : 'line',
      unit: 'kW',
    };
  }

  /**
   * Returns calculated house consumption chart options used in hybrid systems
   */
  get calculatedHouseChartOptions() {
    const calculationExpression = getCalculationHouse(this.deviceData);
    return {
      calculation: {
        aggregations: calculationExpression.aggregations,
        expression: calculationExpression.expression,
      },
      name: 'House',
      scaling: singleEnergyChartScaling,
      type: this.showColumnChart ? 'column' : 'line',
      unit: 'kW',
      seriesType: 'Calculation',
    };
  }

  get isSystemWithMultipleChartValues() {
    return this.currentChart.system ? this.systemsVars[this.currentChart.system].length > 1 : false;
  }

  /**
   * Systems options data
   */
  get systemsChartOptions() {
    if (!this.currentChart.instances.length) return [];

    const chartOptions: any = this.currentChart.instances.map((instance: string) => {
      const existingSystemVars = this.systemsVars[this.currentChart.system as string].filter((v: string) => {
        return !!this.getVariableValue(this.deviceData, this.currentChart.system as EnergyCircleType, instance, v);
      });

      return existingSystemVars.map((variable: string) => {
        const chartInstanceName = getChartSystemInstanceName(this.getSystems(this.deviceData), this.currentChart.system as string, instance, variable);

        const name = this.isSystemWithMultipleChartValues
          ? `${this.$t(`devices.EnergyView.ChartsWindow.legend.${this.currentChart.system}.${variable}`)} - ${chartInstanceName}`
          : chartInstanceName;

        const scaling = this.systemsScaling[this.currentChart.system as string];
        const units = this.systemsUnits[this.currentChart.system as string];

        if (!this.showColumnChart) {
          return {
            calculation: getCalculation({
              system: this.currentChart.system as EnergyCircleType,
              instance,
              variable,
              device: this.deviceData,
              expressionWrapperFn: this.setExpressionAsMax
                ? (exp: string) => `max(${exp}, 0)`
                : this.getSystemsExpressionWrappers(this.currentChart.system),
              externalMeasurementsList: this.externalMeasurementsSystemsVariablesList,
              defaultAggregation: this.defaultAggregation,
            }),
            name,
            scaling: scaling ? scaling[variable] : singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: units ? units[variable] : 'kW',
          };
        } else {
          const energyCounterMapping = this.getSystemComponents(this.deviceData, this.currentChart.system as EnergyCircleType)[instance][variable];
          return {
            var: energyCounterMapping,
            name: `${this.$t(`devices.EnergyView.ChartsWindow.legend.${this.currentChart.system}.${variable}`)} - ${chartInstanceName}`,
            scaling: scaling ? scaling[variable] : singleEnergyChartScaling,
            seriesType: 'View',
            type: 'column',
            unit: 'kWh',
            agg: 'diff',
          };
        }
      });
    });
    return chartOptions.flat().filter(Boolean);
  }

  getSystemTitle(system: string, instance: string) {
    if (['battery', 'grid'].includes(system)) {
      const { title } = this.getSystems(this.deviceData)[system].components[instance.split('_')[0]];
      return title || instance;
    } else {
      const { title } = this.getSystems(this.deviceData)[system].components[instance];
      return title || instance;
    }
  }

  get externalMeasurementsSystemsVariablesList() {
    const list = this.externalMeasurementsSystems.map((type: EnergyCircleType) => {
      const components = this.getSystemComponentsWithEnabledExternalMeasurements(this.deviceData, type);
      const variables = Object.values(components).map((mappings: Record<string, string>) => mappings?.power);
      return variables.filter((v: string | undefined) => v?.length);
    });
    return list.flat();
  }

  /**
   * Creates an understandable object for the graphs component
   */
  get currentChartData() {
    let chartOptions = this.showHouseConsumptionAlways
      ? [...(this.currentChart.instances.every((sys: any) => sys.includes('house')) ? [] : [this.houseChartOptions]), ...this.systemsChartOptions]
      : [...this.systemsChartOptions];

    // if house is selected and hybrid system is enabled, we show the calculated house consumption
    if (this.currentChart.system === EnergyCircleType.house && this.isHybridSystem && !this.showColumnChart) {
      chartOptions = [{ ...this.calculatedHouseChartOptions }];
      this.showHouseCalculationTooltip = true;
    } else if (this.showHouseConsumptionAlways && this.isHybridSystem && !this.showColumnChart) {
      // for self consumption chart we show the calculated house consumption and the systems chart option
      chartOptions = [{ ...this.calculatedHouseChartOptions }, ...this.systemsChartOptions];
      this.showHouseCalculationTooltip = true;
    } else {
      // disable tooltip
      this.showHouseCalculationTooltip = false;
    }

    return {
      title: null,
      data: {
        chartTitle: '',
        chartWidth: null,
        chartHeight: 600,
        chartWrapperHeight: 750,
        chartData: {
          data: { chartOptions },
        },
        navigationItemsToExclude: this.navItemsToExclude,
      },
    };
  }

  get showCurrentChartData() {
    return this.currentChartData?.data?.chartData?.data?.chartOptions?.length;
  }

  handleCurrentChart(params: {system: EnergyCircleType; arr: string[]}, selected = 0) {
    this.currentChart = { system: params.system, instances: params.arr };
    this.selectedGroup = selected;
    this.reRenderKey += 1;
  }

  /**
   * Init what system chart will load when open page.
   */
  initFirstSystemByDefault() {
    const filtered: any = this.systemSchema.filter((el: any) => el[1].length);
    this.currentChart = filtered.length
      ? {
        system: filtered[0][0],
        instances: filtered[0][1][0],
      } : {
        system: null,
        instances: [],
      };
  }

  async chartRangeChange(params: any) {
    this.start = params.start;
    this.end = params.end;
    this.currentPeriod = params.period;
    const fetchEnergyDataFunction = this.isMpc ? this.fetchDataByPeriodMpc : this.fetchDataByPeriod;
    this.energyResponse = await fetchEnergyDataFunction({
      id: this.mpcId.replaceAll('_', '-'),
      start: this.start,
      end: this.end,
      period: this.currentPeriod,
    });

    const system: EnergyCircleType = this.systemSchema[this.selectedGroup][0];
    const instances = this.systemSchema[this.selectedGroup][1];
    const scores: Record<string, number | null> = {};
    const scoresForDataExport: any = {};
    const total_consumption = this.energyResponse?.calculations?.total_consumption;
    const energyResponseSystemData = this.energyResponse?.energies?.[system];
    if (this.systemSchema.length > 0 && energyResponseSystemData) {
      if (system === 'house' && (this.isHybridSystem || instances[0].length === 1)) {
        // calculate house value similar to house value in energy flow view
        const externalMeasurementsSystemsValueList = this.externalMeasurementsSystems.map((systemType: EnergyCircleType) => {
          return this.getSumOfExternalMeasurementsSystemInstancesValues(systemType, this.energyResponse, this.deviceData) ?? 0;
        });
        const sumOfExternalMeasurementsSystemsValues = externalMeasurementsSystemsValueList.reduce((acc: number, val: number) => acc + val, 0);
        const returnVal = this.getHouseValue(total_consumption, sumOfExternalMeasurementsSystemsValues)?.toFixed(2) ?? 0;
        const houseValWithoutExternalMeasurements = typeof returnVal === 'string' ? parseFloat(returnVal) : returnVal;

        Object.entries(energyResponseSystemData).forEach(([instance, _]: [string, number]) => {
          scores[this.getSystemTitle(system, instance)] = total_consumption
            ? houseValWithoutExternalMeasurements / instances.length
            : null;
          scoresForDataExport[this.getSystemTitle(system, instance)] = {
            value: total_consumption ? Math.max(roundNumber(total_consumption / instances.length)) : null,
            unit: 'kWh',
          };
        });
      } else {
        Object.entries(energyResponseSystemData).forEach(([instance, value]: [string, number]) => {
          scores[this.getSystemTitle(system, instance)] = Math.max(roundNumber(value), 0);
          scoresForDataExport[this.getSystemTitle(system, instance)] = { value: Math.max(roundNumber(value), 0), unit: 'kWh' };
        });
      }
    }
    this.scores = scores;
    this.scoresForDataExport = scoresForDataExport;
  }

  created() {
    this.systems = this.shownSystems;
    if (this.navItemsToExclude.includes('day')) {
      this.currentPeriod = Periods.WEEK;
    }
    this.initFirstSystemByDefault();
  }
}
