
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import EnergyVisualisation
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import {
  allEnergyCircleTypes,
  energyCircleIconForType,
  EnergyCircleType,
  getCirclePositionForType,
  isProducer,
  isSystemWithAlternativeValue,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  EnergyCircleDisplayData,
  IconContent,
  SocContent,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  getDefaultArrowDirection,
  getDefaultLineColors,
  getDefaultSpinnerColor,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import {
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import ActualViewSystemDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/ActualViewSystemDialog.vue';
import { IDevice } from '@/types/devices.types';
import Unit from '@/utils/unit/unit';
import {
  energyGreyColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';

type SystemInstances = Record<string, SystemInstance>
type SystemInstance = Record<string, string>

const devicesWithDynamicBottomValue: string[] = ['EMS', 'EMSV2'];

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    EnergyVisualisation,
    ActualViewSystemDialog,
  },
  computed: {
    devicesWithDynamicBottomValue: () => devicesWithDynamicBottomValue,
  },
})
export default class ActualView extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: false }) enableMinWidth!: boolean;
  @Prop({ default: false }) isHybridSystem!: boolean;
  @Getter('projects/project') project!: IProject;

  showDialog = false;
  showSystemSettingsDialog = false;
  openedSystem?: EnergyCircleType;
  previousHouseValue = -1;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;

  onLineAnimationEndCallback(id: string) {
    if (id === EnergyCircleType.electric_heating) {
      this.electricHeatingAnimationBottomValue = !this.electricHeatingAnimationBottomValue;
    }
  }

  electricHeatingAnimationBottomValue = false;

  @Provide('onCircleClick') onCircleClick = this.onCircleClickCallback;

  onCircleClickCallback(circleData: EnergyCircleDisplayData) {
    this.openedSystem = circleData.id as EnergyCircleType;
    this.showDialog = true;
  }

  sum(systemInstances: Record<string, Record<string, string>>, mappedVariable = 'power') {
    const values = Object.values(systemInstances).map(
      (systemInstanceMappings: Record<string, string>) => {
        const measurement = systemInstanceMappings[mappedVariable];
        return this.measurements[measurement];
      },
    );
    if (values.every((value: any) => typeof value !== 'number')) return;

    return values.reduce((acc: number, value: any) => {
      const valueNumber = typeof value === 'number' ? value : 0;
      return acc + valueNumber;
    }, 0);
  }

  avg(systemInstances: Record<string, Record<string, string>>, mappedVariable = 'soc', skipExternal = false) {
    let systemInstanceArray = Object.values(systemInstances);

    if (skipExternal) {
      // filter out external energy measurements for average calculation
      systemInstanceArray = systemInstanceArray.filter((systemInstance: any) => {
        return systemInstance.external_energy_measurement !== true;
      });
    }

    const values: number[] = systemInstanceArray.map(
      (systemInstanceMappings: Record<string, string>) => {
        const measurement = systemInstanceMappings[mappedVariable];
        return this.measurements[measurement] as number;
      },
    );
    const sum: number = values.reduce((acc: number, value: number) => acc + value, 0);
    const result = sum / values.length;
    return Number.isNaN(result) ? 0 : result;
  }

  getErrorWarningMode(systemInstances: SystemInstances) {
    const systemInstancesErrorValues = Object.values(systemInstances)
      .map((instance: SystemInstance) => this.measurements[instance.error])
      .filter((el: any) => typeof el === 'number');

    return systemInstancesErrorValues.length ? Math.max.apply(null, systemInstancesErrorValues as number[]) : undefined;
  }

  shouldShowCross(systemType: string, deviceData: IDevice) {
    if (systemType === EnergyCircleType.grid) {
      const variablenameToCheck = deviceData.data.meta.controllerMappings.grid.components.grid1.is_on_grid;
      if (this.measurements[variablenameToCheck] === 1) {
        return true;
      }
    }
    return false;
  }

  get forceCharge() {
    return this.measurements[this.deviceData.data.meta.controllerMappings.force_charge];
  }

  get forceCharge2() {
    return this.measurements[this.deviceData.data.meta.controllerMappings.force_charge_2];
  }

  get calibrationCharge() {
    return this.measurements[this.deviceData.data.meta.controllerMappings.calibration_charge];
  }

  get maintenanceCharge() {
    return this.measurements[this.deviceData.data.meta.controllerMappings.maintenance_charge];
  }

  get chargeMaintenance() {
    return this.measurements[this.deviceData.data.meta.controllerMappings.charge_maintenance];
  }

  get shownSystems() {
    return this.filterPresentSystems(this.deviceData, allEnergyCircleTypes);
  }

  get houseSystemOriginalValue() {
    if (!this.isSystemPresent(this.deviceData, EnergyCircleType.house)) return undefined;

    const components = this.getSystemComponents(this.deviceData, EnergyCircleType.house);
    return this.getSumOfSystemInstancesValues({
      systemInstances: components,
      measurements: this.measurements,
    }) ?? 0;
  }

  /**
   * Returns the sum of all power values that are needed for the house power calculation
   * from producer elements (pv, generator, battery, chp)
   */
  get houseCalculationProducerElementsSum() {
    const producerElements = [
      EnergyCircleType.pv,
      EnergyCircleType.generator,
      EnergyCircleType.battery,
      EnergyCircleType.chp,
      EnergyCircleType.grid,
    ];
    return producerElements.reduce((acc: number, systemType: EnergyCircleType) => {
      if (!this.isSystemPresent(this.deviceData, systemType)) return acc;

      const components = this.getSystemComponents(this.deviceData, systemType);
      let sum = this.getSumOfSystemInstancesValues({
        systemInstances: components,
        measurements: this.measurements,
      });

      // convert new value to positive value if device is a producer
      if (isProducer(systemType)) {
        if (sum !== undefined && sum < 0) {
          sum *= -1;
        }
      }

      return acc + (sum ?? 0);
    }, 0);
  }

  /**
   * Returns the sum of all power values that are needed for the house power calculation
   * from consumer elements (charge_station, electric_heating, heating_pump, big_consumer)
   */
  get houseCalculationConsumerElementsSum() {
    const consumerElements = [
      EnergyCircleType.charge_station,
      EnergyCircleType.electric_heating,
      EnergyCircleType.heating_pump,
      EnergyCircleType.big_consumer,
    ];
    return consumerElements.reduce((acc: number, systemType: EnergyCircleType) => {
      if (!this.isSystemPresent(this.deviceData, systemType)) return acc;

      const components = this.getSystemComponents(this.deviceData, systemType);
      let sum = this.getSumOfSystemInstancesValues({
        systemInstances: components,
        measurements: this.measurements,
      });

      // convert to positive if sum i negative
      if (sum !== undefined && sum < 0) {
        sum *= -1;
      }

      return acc + (sum ?? 0);
    }, 0);
  }

  /**
   * Calculated Value for the house system
   */
  get houseSystemValueHybridSystem() {
    return this.houseCalculationProducerElementsSum - this.houseCalculationConsumerElementsSum;
  }

  get houseSystemValue() {
    return this.getHouseValue(this.houseSystemOriginalValue, this.sumOfAllEnabledExternalMeasurementsSystemsValues);
  }

  get electricHeatingValueUnit(): { v?: number; u?: Unit; isTemperature?: boolean } {
    const components = this.getSystemComponents(this.deviceData, EnergyCircleType.electric_heating);
    const power = this.getSumOfSystemInstancesValues({
      systemInstances: components,
      measurements: this.measurements,
    });
    const temperature = this.getAverageOfSystemInstancesValues({
      systemInstances: components,
      measurements: this.measurements,
      mappedVariable: 'temperature',
    });
    return this.getElectricHeatingValue({
      power,
      temperature,
      status: this.electricHeatingAnimationBottomValue,
    });
  }

  get circlesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType) => {
      const components = this.getSystemComponents(this.deviceData, systemType);
      const position = getCirclePositionForType(systemType);
      let bottomValue;
      let unit;
      let additionalContent: any = {};
      let bottomDynamicValue;
      let disableSpinner;

      if (systemType === EnergyCircleType.house) {
        const houseSystemCount = this.getSystemCount(this.deviceData, EnergyCircleType.house);
        if (this.previousHouseValue < 0 && houseSystemCount === 1) {
          // if previous value is negative (only initialy) use calculated value (positive and negative)
          // also check for amount of houses. caluclated value is only used if there is only one house
          bottomValue = this.houseSystemValueHybridSystem;
          // if there is a hybrid system and the house value is negative, show the loading animation until the house value is positive or 0
          additionalContent = {
            showLoadingAnimationHouseCircle: this.isHybridSystem && this.houseSystemValueHybridSystem < 0 && this.previousHouseValue < 0,
          };
        } else if (this.previousHouseValue >= 0 && houseSystemCount === 1) {
          // if the previous value is positive and there is a hybrid system, show 0 else show the calculated value (if positive)
          // also check for amount of houses. caluclated value is only used if there is only one house
          bottomValue = this.houseSystemValueHybridSystem < 0 ? this.previousHouseValue : this.houseSystemValueHybridSystem;
          additionalContent = {
            showLoadingAnimationHouseCircle: false,
          };
        } else {
          // if there is no hybrid system, show the normal house value from plc
          bottomValue = this.houseSystemValue;
        }
        if ((bottomValue as number) < 0) {
          // if the house value is negative, show 0 or the previous value if it was positive
          if (this.previousHouseValue < 0) {
            bottomValue = 0;
          } else {
            bottomValue = this.previousHouseValue;
          }
        }
        this.previousHouseValue = (bottomValue as number) ?? 0;
        if (!this.isHybridSystem) {
          // if there is no hybrid system we never show the loading animation
          additionalContent = {
            showLoadingAnimationHouseCircle: false,
          };
        }
      } else if (systemType === EnergyCircleType.electric_heating) {
        if (devicesWithDynamicBottomValue.includes(this.deviceData.data.type)) {
          bottomValue = this.electricHeatingValueUnit.v;
          unit = this.electricHeatingValueUnit.u;
          bottomDynamicValue = this.electricHeatingValueUnit.isTemperature;

          const power = this.getSumOfSystemInstancesValues({
            systemInstances: components,
            measurements: this.measurements,
          }) ?? 0;

          // if the power is negative and the temperature is visible, disable the spinner
          disableSpinner = this.electricHeatingValueUnit.isTemperature && power <= 0;
        } else {
          bottomValue = this.getSumOfSystemInstancesValues({
            systemInstances: components,
            measurements: this.measurements,
          });
        }
      } else {
        bottomValue = this.getSumOfSystemInstancesValues({
          systemInstances: components,
          measurements: this.measurements,
        });
      }

      if (systemType === EnergyCircleType.battery) {
        // here we add all the needed information for the forceChargeIndicator
        additionalContent = {
          forceCharge: this.forceCharge === 1 || this.forceCharge2 === 1,
          calibrationCharge: this.calibrationCharge === 1,
          maintenanceCharge: this.maintenanceCharge === 1,
          chargeMaintenance: this.chargeMaintenance === 1,
        };
      }

      let centerContent: IconContent | SocContent = {
        type: 'icon',
        value: energyCircleIconForType(systemType),
      };
      if (systemType === EnergyCircleType.battery) {
        centerContent = { type: 'soc', value: this.avg(components, 'soc', true) ?? 0 };
      }

      // consumers (except house) look on target_power, if power is not mapped, for arrow animation
      let alternativeValue;
      if (isSystemWithAlternativeValue(systemType) && !bottomValue) {
        alternativeValue = this.getSumOfSystemInstancesValues({
          systemInstances: components,
          measurements: this.measurements,
          mappedVariable: 'target_power',
        });
      }

      let currentSpinnerColor = getDefaultSpinnerColor(systemType, bottomValue ?? alternativeValue);
      if (systemType === EnergyCircleType.electric_heating && this.electricHeatingValueUnit.isTemperature && !disableSpinner) {
        // if the boiler temperature is visible, the spinner color is dependent on the power value not the temperature value
        // we then also force the animations to be visible
        const power = this.getSumOfSystemInstancesValues({
          systemInstances: components,
          measurements: this.measurements,
        }) ?? 0;
        currentSpinnerColor = getDefaultSpinnerColor(systemType, power);
        additionalContent.forceAnimation = power > 0;
      } else {
        additionalContent.forceAnimation = false;
      }

      if ((bottomValue as number) >= 0.01 && (bottomValue as number) < 0.1) {
        // if value is between 0.01 and 0.1, but the bottom value is 0, show 0.1
        bottomValue = 0.1;
      }

      return {
        id: systemType,
        bottomValue,
        centerContent,
        position,
        systemCount: this.getSystemCount(this.deviceData, systemType),
        spinnerColor: currentSpinnerColor,
        disableSpinner,
        alternativeValue,
        errorWarningMode: this.getErrorWarningMode(components),
        unit,
        bottomDynamicValue,
        additionalDisplayData: additionalContent,
      } as EnergyCircleDisplayData;
    });
  }

  get linesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType, index: number) => {
      const displayData = this.circlesDisplayData[index];
      let arrowDirection;
      let colors;

      // if the temperature is visible and the power is negative, the arrow should not be visible else it should be visible (currently only for electric_heating systems)
      if (systemType === EnergyCircleType.electric_heating && displayData.bottomDynamicValue === true && displayData.disableSpinner) {
        arrowDirection = 0;
        colors = energyGreyColors;
      } else {
        arrowDirection = getDefaultArrowDirection(systemType, displayData);
        colors = getDefaultLineColors(systemType, displayData);
      }

      return {
        id: displayData.id,
        arrowDirection,
        colors,
        showCross: this.shouldShowCross(systemType, this.deviceData),
      } as Partial<EnergyLineDisplayData>;
    });
  }
}
