import { PacketCallback } from 'mqtt';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { IMQTTState } from '@/store/modules/mqtt/types';
import { RootState } from '@/store/types';
import { IPublishRecord } from '@/types/mqtt.types';
import MeasurementService from '@/services/MeasurementService';
import { defaultFeedbackTimeoutMilliSeconds } from '@/utils/constants';

const state: IMQTTState = {
  interval: null,
  online: false,
  internet: false,
  lastHeartbeatMessage: 0,
  lastInternetHeartbeatMessage: 0,
};

const getters: GetterTree<IMQTTState, RootState> = {
  mqttClientInfo(state, getters, rootState, rootGetters) {
    const username = rootGetters['projects/project'].id;
    const password = rootGetters['projects/project'].secret ?? '';
    const hostname = 'mqtt.lynus.io';
    return {
      username,
      password,
      port: '8883',
      hostname,
      topicPublish: `projects/${rootGetters['projects/project'].id}/messages`, // configuration valid for a client's device
      topicSubscribe: `projects/${rootGetters['projects/project'].id}/messages2`, // configuration valid for a client's device
    };
  },
  isProjectOnline(state: IMQTTState): boolean {
    return state.online;
  },
  isProjectConnected(state: IMQTTState): boolean {
    return state.internet;
  },
};

const mutations: MutationTree<IMQTTState> = {
  setOnline(state, status) {
    state.online = status;
  },
  setInternet(state, status) {
    state.internet = status;
  },
  clearInterval(state) {
    clearInterval(state.interval);
    state.interval = null;
  },
  setLastHeartbeatMessage(state, value) {
    state.lastHeartbeatMessage = value;
  },
  setLastInternetHeartbeatMessage(state, value) {
    state.lastInternetHeartbeatMessage = value;
  },
};

const actions: ActionTree<IMQTTState, RootState> = {
  async publish({ commit, state, getters, rootGetters }, { records, callback }: {
    records: IPublishRecord[];
    callback: PacketCallback;
  }) {
    const string = JSON.stringify(records);
    await MeasurementService.sendMeasurements(rootGetters['projects/project'].id, records);
    records.forEach((record: any) => commit('measurements/setMeasurement', record, { root: true }));
  },
  async createConnection({ commit, state, getters, rootGetters, dispatch }) {
    // generate more random clientId than default. use same as in app. (length 23)
    const project = rootGetters['projects/project'];

    function handleProjectState() {
      // try to get measurement "hb" from measurements
      const heartBeat = !!rootGetters['measurements/measurements'].$hb;
      if (heartBeat) {
        state.lastHeartbeatMessage = rootGetters['measurements/measurements'].$hb;
      }

      if (state.lastHeartbeatMessage !== null && state.lastHeartbeatMessage > 0) {
        const heartbeatOffset = defaultFeedbackTimeoutMilliSeconds;
        if ((Date.now() - state.lastHeartbeatMessage) > heartbeatOffset) {
          commit('setOnline', false);
        } else {
          commit('setOnline', true);
        }
      }
    }

    function handleInternetState() {
      // try to get measurement "hb2" from measurements
      const heartBeat = !!rootGetters['measurements/measurements'].$hb2;
      if (heartBeat) {
        state.lastInternetHeartbeatMessage = rootGetters['measurements/measurements'].$hb2;
      }

      if (state.lastInternetHeartbeatMessage !== null && state.lastInternetHeartbeatMessage > 0) {
        const heartbeatOffset = defaultFeedbackTimeoutMilliSeconds;
        if ((Date.now() - state.lastInternetHeartbeatMessage) > heartbeatOffset) {
          commit('setInternet', false);
        } else {
          commit('setInternet', true);
        }
      }
    }

    await dispatch('measurements/fetchMeasurements', project.id, { root: true });
    handleProjectState();
    handleInternetState();

    state.interval = setInterval(async () => {
      let attempts = 0;
      let success = false;

      while (attempts < 3 && !success) {
        try {
          await dispatch('measurements/fetchMeasurements', project.id, { root: true });
          success = true;
        } catch (error) {
          attempts += 1;
          if (attempts >= 3) {
            console.error('Failed to fetch measurements after 3 attempts', error);
          }
        }
      }
      handleProjectState();
      handleInternetState();
    }, 15000);
  },
  async removeConnection({ state, commit }) {
    commit('setOnline', false);
    commit('setInternet', false);
    commit('setLastHeartbeatMessage', 0);
    commit('setLastInternetHeartbeatMessage', 0);
    commit('clearInterval');
  },
};

export const mqttClient: Module<IMQTTState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
