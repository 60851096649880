
import { Component, Vue } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import { IEMSControllerMappings } from '@/types/mpc/devices/ems.types';
import { ICountersObject, IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';
import {
  houseMappingEMSTenant,
  batteryMappingEMSTenant,
  houseMappingEnergyViewTenant,
  gridMappingEnergyViewTenant,
  batteryMappingEnergyViewTenant,
} from '@/ui/components/wizards/tenantWizard/wizardSettings/defaultMappings';
import { filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import { convertDecimalNumberToBinary, convertBitToArray } from '@/utils/utilsFunctions';
import { IMeasurements } from '@/types/measurements.types';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';
import { MQTTFeedbackError } from '@/store/modules/installationWizard/MQTTFeedbackError';
import { IWizardLoadingState } from '@/types/wizards/wizard.general.types';
import { IReportBox } from '@/types/app.types';
import { plcVersionDate, tenantUpdateWagoCounters } from '@/utils/versionManagementUtils';
import { defaultTimeoutRepotMessages } from '@/utils/constants';
import { gridMappingEMS, pvMappingEMS, pvMappingEnergyView } from '../../../installationWizard/wizardSettings/defaultMappings';

@Component({
  components: {
    WizardContentView,
  },
})
export default class WelcomePage extends Vue {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('tenantWizard/hasLynusInverter') hasLynusInverter!: boolean;
  @Getter('tenantWizard/overallProductionMeasurement') overallProductionMeasurement!: string;
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Mutation('tenantWizard/setWasTenantDoneState') setWasTenantDoneState!: (data: boolean) => void;
  @Mutation('tenantWizard/setWasTenantRestarted') setWasTenantRestarted!: (data: boolean) => void;
  @Mutation('tenantWizard/resetPageStepIndex') resetPageStepIndex!: () => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  @Action('tenantWizard/updateTenant') updateTenant!: (data: { project_id: string; tenant: ITenantModel }) => void;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('installationWizard/sendVariables') sendVariables!: (variablesToSend: IMQTTVariable[]) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Mutation('installationWizard/setLoadingState') setLoadingState!: (payload: IWizardLoadingState) => void;
  @Mutation('installationWizard/resetLoadingState') resetLoadingState!: () => void;
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;

  variablesToSend: IMQTTVariable[] = [];

  get emsDevice() {
    return this.allDevices.find((device) => device.data.type === 'EMSV2');
  }
  get energyViewDevice() {
    return this.allDevices.find((device) => device.data.type === 'EnergyViewV2');
  }

  async handleNext() {
    if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      const res = await this.updateMeasurements();
      if (!res) return;
      await this.updateEnergyDevices();
    }
    await this.updateTenantModel();
    await this.handleUpdateProject();
    this.setWasTenantDoneState(true);
    this.setWasTenantRestarted(false);
    this.resetPageStepIndex();
  }

  async updateMeasurements() {
    const gridNumber = filterNumberFromString(this.tenantState.tenant.general.gridCounter);

    // if there is no lynus inverter we need to set the grid measurement and disable the battery
    if (!this.hasLynusInverter) {
      this.variablesToSend = [
        {
          variable: 'prgEnergy.fbI.bEnable',
          value: 0,
          feedbackVariable: 'prgEnergy.fbI.stDataBattInverter.bEnabled',
          isBoolean: true,
        },
        {
          variable: 'prgEnergy.wSetGridMessurement',
          value: gridNumber,
          isBoolean: true,
        },
      ];
    } else {
      // if there is a lynus inverter we need to set the grid measurement to 0
      this.variablesToSend = [
        {
          variable: 'prgEnergy.wSetGridMessurement',
          value: 0,
          isBoolean: true,
        },
      ];
    }

    try {
      this.setLoadingState({
        isLoading: true,
        loadingCount: 0,
        loadingTotal: this.variablesToSend.length,
      });
      await this.sendVariables(this.variablesToSend);
      this.resetLoadingState();
      this.handleIncrement();
      return true;
    } catch (e) {
      if (e instanceof MQTTFeedbackError) {
        this.resetLoadingState();
        this.setReport({
          type: 'error',
          message: this.$t('installationWizard.mqttFeedbackErrorMessage', { variable: e.variable }),
          value: true,
          timeout: defaultTimeoutRepotMessages,
        });
      }
      this.resetLoadingState();
      return false;
    }
  }

  async updateEnergyDevices() {
    const emsDeviceCopy: any = { ...this.emsDevice };
    const energyViewDeviceCopy: any = { ...this.energyViewDevice };
    const mappingsEMS: IEMSControllerMappings = { ...emsDeviceCopy.data.meta.controllerMappings };
    const mappingsEnergyView: any = { ...energyViewDeviceCopy.data.meta.controllerMappings };

    // replace house controller mappings with the once from the tenant
    // this is needed for both cases, hybrid system and tenant without lynus battery
    mappingsEMS.house.count = 0;
    mappingsEnergyView.house.count = 0;
    mappingsEMS.house.components = {};
    mappingsEnergyView.house.components = {};
    const overallConsumptionCounters = this.tenantState.tenant.general.overallCounters.energyCounter;
    const flatEnergyCounters: ICountersObject[] = [];
    this.tenantState.tenant.general.flats.flatConfigurations.forEach((flat: IFlatsConfig) => {
      const flatName = flat.name;
      flat.counters.energyCounter.forEach((counter) => {
        // if we have more than one counter in one flat the name will be the flat name + the counter name
        const object: ICountersObject = {
          id: counter.id,
          name: flat.counters.energyCounter.length > 1 ? `${flatName}_${counter.name}` : flatName,
        };
        flatEnergyCounters.push(object);
      });
    });
    // combine overall consumption counters with flat counters to get all counters needed for the house mappings
    const combinedArray = overallConsumptionCounters.concat(flatEnergyCounters);
    mappingsEMS.house.count = combinedArray.length;
    mappingsEnergyView.house.count = combinedArray.length;
    combinedArray.forEach((counter: ICountersObject, index: number) => {
      if (counter.id.includes('prgCS.lrCounterCS_')) {
        mappingsEMS.house.count--;
        mappingsEnergyView.house.count--;
        return; // do not add charge station counters in the house mappings
      }
      const element = houseMappingEMSTenant(index + 1);
      element.title = counter.name;
      element.energy_counter = counter.id;
      element.power = `prgEM.lrPower_E_${filterNumberFromString(counter.id)}`;
      mappingsEMS.house.components[`house${index + 1}`] = element;

      const elementEnergyView = houseMappingEnergyViewTenant(index + 1);
      elementEnergyView.title = counter.name;
      elementEnergyView.energy_counter = counter.id;
      elementEnergyView.power = `prgEM.lrPower_E_${filterNumberFromString(counter.id)}`;
      mappingsEnergyView.house.components[`house${index + 1}`] = elementEnergyView;
    });

    // replace grid controller mappings with the once from the tenant
    if (!this.hasLynusInverter) {
      mappingsEMS.grid.components = {};
      mappingsEnergyView.grid.components = {};
      const gridMappings = gridMappingEMS();
      gridMappings.energy_counter = this.tenantState.tenant.general.gridProductionCounter;
      gridMappings.size_in_amps = this.emsDevice?.data.meta.controllerMappings.grid.components.grid1?.size_in_amps ?? 0;
      gridMappings.power = `prgEM.lrPower_E_${filterNumberFromString(this.tenantState.tenant.general.gridCounter)}`;
      gridMappings.reverse_energy_counter = this.tenantState.tenant.general.gridCounter;
      mappingsEMS.grid.components.grid1 = gridMappings;
      const gridMappingsEnergyView = gridMappingEnergyViewTenant();
      mappingsEnergyView.grid.components.grid1 = {
        ...gridMappingsEnergyView,
        energy_counter: this.tenantState.tenant.general.gridProductionCounter,
        reverse_energy_counter: this.tenantState.tenant.general.gridCounter,
        power: `prgEM.lrPower_E_${filterNumberFromString(this.tenantState.tenant.general.gridCounter)}`,
      };
    }

    if (!this.hasLynusInverter) {
      // clear all batterie and pv mappings
      const componentKeysToClear = ['battery', 'pv'];
      componentKeysToClear.forEach((key) => {
        mappingsEMS[(key as keyof IEMSControllerMappings)].count = 0;
        mappingsEMS[(key as keyof IEMSControllerMappings)].components = {};
        mappingsEnergyView[key].count = 0;
        mappingsEnergyView[key].components = {};
      });
    } else if (this.hasLynusInverter) {
      // remove the mappings added by the tenant wizard to avoid duplicates
      const componentsToCheck = ['battery', 'pv'];
      componentsToCheck.forEach((key) => {
        Object.values(mappingsEMS[(key as keyof IEMSControllerMappings)].components).forEach((component: any, index: number) => {
          if (component.power.includes('prgEM.lrPower_E_')) {
            mappingsEMS[(key as keyof IEMSControllerMappings)].count--;
            mappingsEnergyView[(key as keyof IEMSControllerMappings)].count--;
            delete mappingsEMS[(key as keyof IEMSControllerMappings)].components[`${key}${index + 1}`];
            delete mappingsEnergyView[(key as keyof IEMSControllerMappings)].components[`${key}${index + 1}`];
          }
        });
      });
    }

    // if hasLynusInverter is false and we have a overallProductionMeasurement we add a pv mapping
    if (!this.hasLynusInverter) {
      const id = filterNumberFromString(this.overallProductionMeasurement);
      // if we have a overall production measurement and there is no lynus inverter, we add a pv mapping
      if (!this.hasLynusInverter) {
        const element = pvMappingEMS(1);
        element.power = `prgEM.lrPower_E_${id}`;
        element.energy_counter = `prgEM.lrCounter_E_P_${id}`;
        element.title = 'Produktion Total';
        mappingsEMS.pv.components.pv1 = element;
        mappingsEMS.pv.count++;

        const elementEnergyView = pvMappingEnergyView(1);
        elementEnergyView.power = `prgEM.lrPower_E_${id}`;
        elementEnergyView.energy_counter = `prgEM.lrCounter_E_P_${id}`;
        elementEnergyView.title = 'Produktion Total';
        mappingsEnergyView.pv.components.pv1 = elementEnergyView;
        mappingsEnergyView.pv.count++;
      }
    }

    // check if overall production measurement is not set or if there is no lynus inverter
    if (this.overallProductionMeasurement === '' || !this.hasLynusInverter) {
      // add the production mappings from the tenant to the pv mappings
      let productionMappings = this.tenantState.tenant.general.productionCounters ?? [];
      const variablesToFilter = [
        'prgEnergy.lrCounterProdTotal',
        'prgEM.lrCounterProdTotal_2',
      ];
      const containsInternalCounter = productionMappings.filter((counter: string) => variablesToFilter.includes(counter)).length > 0;
      // remove the counters that are not needed for the pv mappings
      productionMappings = productionMappings.filter((counter: string) => !variablesToFilter.includes(counter));
      if (this.hasLynusInverter && !containsInternalCounter) {
        // if digital counters are not present we do not need to add any pv's
        productionMappings = [];
      }
      productionMappings.forEach((counter: string) => {
        // get the number from the counter string
        const number = parseInt(filterNumberFromString(counter), 10) ?? 0;
        if (!this.hasLynusInverter) {
          // if there is no lynus inverter overall measurement must be set
          const overallMeasurementNumber = parseInt(filterNumberFromString(this.overallProductionMeasurement), 10) ?? 0;
          // if the number is the same as the overall measurement number we do not need to add it to the pv mappings
          if (number === overallMeasurementNumber) return;
        }
        // check if the component is already set
        if (!this.checkComponentIsSet(number, mappingsEMS.pv.components)) {
          // if not set add it to the mappings in EMS and EnergyView
          mappingsEMS.pv.count++;
          const element = pvMappingEMS(mappingsEMS.pv.count);
          element.title = `PV ${mappingsEMS.pv.count}`;
          element.energy_counter = counter;
          element.power = `prgEM.lrPower_E_${filterNumberFromString(counter)}`;
          mappingsEMS.pv.components[`pv${mappingsEMS.pv.count}`] = element;

          mappingsEnergyView.pv.count++;
          const elementEnergyView = pvMappingEnergyView(mappingsEMS.pv.count);
          elementEnergyView.title = `PV ${mappingsEMS.pv.count}`;
          elementEnergyView.energy_counter = counter;
          elementEnergyView.power = `prgEM.lrPower_E_${filterNumberFromString(counter)}`;
          mappingsEnergyView.pv.components[`pv${mappingsEMS.pv.count}`] = elementEnergyView;
        }
      });
    }

    // will contain the ac battery numbers if present
    const acBatteryIds = this.getAcBatteryNumbers();
    // add ac batteries if they are present. but only if we have a lynus inverter
    if (acBatteryIds && this.hasLynusInverter) {
      acBatteryIds.forEach((id: number, acBatteryIndex: number) => {
        // check if the battery is already set
        if (!this.checkComponentIsSet(id, mappingsEMS.battery.components)) {
          // if battery is not set add it to the mappings in EMS and EnergyView
          mappingsEMS.battery.count++;
          const currentIndex = mappingsEMS.battery.count;
          const element = batteryMappingEMSTenant();
          element.power = `prgEM.lrPower_E_${id}`;
          element.title = `AC Batterie ${acBatteryIndex + 1}`;
          element.energy_counter = `prgEM.lrCounter_E_P_${id}`;
          element.reverse_energy_counter = `prgEM.lrCounter_E_C_${id}`;
          mappingsEMS.battery.components[`battery${currentIndex}`] = element;

          mappingsEnergyView.battery.count++;
          const elementEnergyView = batteryMappingEnergyViewTenant(currentIndex);
          elementEnergyView.power = `prgEM.lrPower_E_${id}`;
          elementEnergyView.title = `AC Batterie ${acBatteryIndex + 1}`;
          elementEnergyView.energy_counter = `prgEM.lrCounter_E_P_${id}`;
          elementEnergyView.reverse_energy_counter = `prgEM.lrCounter_E_C_${id}`;
          mappingsEnergyView.battery.components[`battery${currentIndex}`] = elementEnergyView;
        }
      });
    }

    // update the devices with the new mappings
    emsDeviceCopy.data.meta.controllerMappings = mappingsEMS;
    energyViewDeviceCopy.data.meta.controllerMappings = mappingsEnergyView;
    await this.updateDevice({ device: emsDeviceCopy, skipReport: false });
    await this.updateDevice({ device: energyViewDeviceCopy, skipReport: false });
  }

  /**
   * Checks if the component with the fitting power mapping is already set in the mappings
   * @param variableIndex
   * @param componentMapping
   */
  checkComponentIsSet(variableIndex: number, componentMapping: any) {
    let returnVal = false;
    Object.values(componentMapping).forEach((component: any) => {
      if (component.power === `prgEM.lrPower_E_${variableIndex}`) {
        returnVal = true;
      }
    });
    return returnVal;
  }

  /**
   * Get the ac battery numbers from the saved value
   */
  getAcBatteryNumbers() {
    // if set saved value will be the decimal equivalent of the bit set with the ac battery numbers
    let savedValue = this.measurements['prgEM.lwPosOfACBattery'];

    // if saved value is undefined we do not need to do anything
    if (!savedValue) return;

    if (typeof savedValue === 'string') {
      savedValue = parseInt(savedValue, 10);
    }
    // if saved value is 0 we do not need to do anything
    if (savedValue === 0) return;

    // convert the decimal number to binary and then to an array of numbers used for the ac battery mapping
    const binaryNumber = convertDecimalNumberToBinary(savedValue).toString();
    return convertBitToArray(binaryNumber) ?? [];
  }

  /**
   * Updates the tenant model in the project meta
   */
  async updateTenantModel() {
    await this.updateTenant({ project_id: this.$route.params.id, tenant: this.tenantState.tenant });
  }

  async handleUpdateProject() {
    const projectCopy = { ...this.project };
    projectCopy.meta.wasTenantWizardCompleted = true;
    if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      // save tenantOnlyProject in project meta this is needed for the installation wizard, if it gets done in the future
      projectCopy.meta.hasLynusInverter = this.hasLynusInverter;
      projectCopy.meta.overallProductionMeasurement = this.overallProductionMeasurement;
    }
    await this.updateProject(projectCopy);
  }

  beforeDestroy() {
    this.setWasTenantDoneState(true);
  }
}
