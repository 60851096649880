
import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { sum } from 'lodash';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import { periodConfigurations, Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import { mixedEnergyChartScaling, singleEnergyChartScaling } from '@/utils/scalingConstants';
import { kiloWattHourUnit } from '@/utils/unit/unitConstants';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  EnergyCircleType,
  producers,
} from '@/types/energyVisualisation/EnergyCircleType';
import { IDevice } from '@/types/devices.types';
import { ChartData, ChartOption } from '@/types/chart.types';

@Component({
  methods: {
    kiloWattHourUnit() {
      return kiloWattHourUnit;
    },
  },
  components: { BaseChartWrapper },
})
export default class EnergyFlowProductionChart extends mixins(EnergyComponent) {
  @State('app') appState!: any;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: '' }) mpcId!: string;
  @Prop() settingsWrapperHeight!: number;
  @Prop({ default: false }) isMpc!: boolean;

  @Action('mpc/fetchDataByPeriodMpc') fetchDataByPeriodMpc!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;
  @Action('devices/fetchDataByPeriod') fetchDataByPeriod!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;

  scores: Record<string, number> = {};
  scoresForDataExport: Record<string, {value: number; unit: string}> = {};
  translation = {
    SelfSufficientScore: this.$t('mlModel.EMS.charts.chartDifference.selfSufficientScore').toString(),
    Eigenverbrauchsanteil: this.$t('mlModel.EMS.charts.chartDifference.eigenverbrauchsanteil').toString(),
    TotalDirectEnergy: this.$t('mlModel.EMS.charts.chartDifference.totalDirectEnergy').toString(),
  }
  units = {
    SelfSufficientScore: '%',
    Eigenverbrauchsanteil: '%',
  }

  period = Periods.DAY;
  start = periodConfigurations[this.period].period().start;
  end = periodConfigurations[this.period].period().end;
  energyResponse: any = null; // TODO: check and add type here (possibly it EnergyDataByPeriodResponse)

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  systemCount(system: EnergyCircleType) {
    return this.getSystemCount(this.deviceData, system);
  }

  get chartData(): ChartData {
    const generatorVariables = this.getVariableMappings(this.deviceData, EnergyCircleType.generator);
    const chpVariables = this.getVariableMappings(this.deviceData, EnergyCircleType.chp);
    const pvVariables = this.getVariableMappings(this.deviceData, EnergyCircleType.pv);
    const productionSum = [...pvVariables, ...generatorVariables, ...chpVariables].join('+');

    const batteryVariableSum = this.getVariableMappings(this.deviceData, EnergyCircleType.battery).join('+');
    const gridVariableSum = this.getVariableMappings(this.deviceData, EnergyCircleType.grid).join('+');

    const batteryPowers = this.getVariableMappings(this.deviceData, EnergyCircleType.battery);
    const gridPowers = this.getVariableMappings(this.deviceData, EnergyCircleType.grid);
    const producersPowers = producers.flatMap((t: EnergyCircleType) => this.getVariableMappings(this.deviceData, t));
    const productionGridVariables = [...productionSum, gridPowers].join('+');
    const batterySocMapping = this.getVariableMappings(this.deviceData, EnergyCircleType.battery, 'soc')
      .filter((element: string | undefined) => element !== '' && element !== undefined);

    return {
      data: {
        chartOptions: [
          ...(producersPowers.length ? [{
            calculation: {
              aggregations: new Array(producersPowers.length).fill('avg'),
              expression: `max(${producersPowers.join(' + ')}, 0)`,
            },
            name: this.$t('mlModel.EMS.charts.chartDifference.production').toString(),
            scaling: singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: 'kW',
          } as ChartOption] : []),
          ...(this.isSystemPresent(this.deviceData, EnergyCircleType.battery) ? [{
            calculation: {
              aggregations: new Array(batteryPowers.length + productionSum.length).fill('avg'),
              expression: `max(min(min(${batteryPowers.join(' + ')}, 0) * -1, ${[...pvVariables, ...generatorVariables, ...chpVariables].join(' + ')}), 0)`,
            },
            name: this.$t('mlModel.EMS.charts.chartDifference.productionBattery').toString(),
            scaling: singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: 'kW',
          } as ChartOption] : []),
          ...(this.isSystemPresent(this.deviceData, EnergyCircleType.grid) ? [{
            calculation: {
              aggregations: new Array(productionGridVariables.length).fill('avg'),
              expression: `max(min(min(${batteryPowers.join(' + ')}, 0) * -1, ${[...pvVariables, ...generatorVariables, ...chpVariables].join(' + ')}), 0)`,
            },
            name: this.$t('mlModel.EMS.charts.chartDifference.productionGrid').toString(),
            scaling: singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: 'kW',
          } as ChartOption] : []),
          ...(this.isSystemPresent(this.deviceData, EnergyCircleType.pv) ? [{
            calculation: {
              // ['avg', ...] array with length of total variables pv, battery, grid
              aggregations: new Array(
                this.systemCount(EnergyCircleType.pv)
                  + this.systemCount(EnergyCircleType.generator)
                  + this.systemCount(EnergyCircleType.chp)
                  + this.systemCount(EnergyCircleType.battery)
                  + this.systemCount(EnergyCircleType.grid),
              ).fill('avg'),
              // sum(pv_power) + sum(bprod_power) + sum(gprod_power),
              expression: `max(max(${productionSum}, 0) + min(${batteryVariableSum || 0},0) + min(${gridVariableSum || 0},0), 0)`,
            },
            name: this.$t('mlModel.EMS.charts.chartDifference.directConsumption').toString(),
            scaling: singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: 'kW',
          } as ChartOption] : []),
          ...(this.isSystemPresent(this.deviceData, EnergyCircleType.battery) && this.superAdmin ? [{
            calculation: {
              aggregations: new Array(batterySocMapping.length).fill('avg'),
              // calculate average of all battery socs
              expression: `( ${batterySocMapping.join(' + ')} ) / ${batterySocMapping.length}`,
            },
            name: this.$t('mlModel.EMS.charts.chartTimeTableConsumer.soc').toString(),
            scaling: { min: 0, max: 100 },
            seriesType: 'Calculation',
            type: 'line',
            unit: '%',
          } as ChartOption] : []),
        ],
      },
    };
  }

  roundNumber(num: number) {
    return Number(num.toFixed(2));
  }

  systemFilterSum(system: any, containedString: string) {
    let sum = 0;
    if (!this.energyResponse.energies[system]) return sum;
    Object.entries(this.energyResponse.energies[system]).forEach(
      (system: any) => {
        if (system[0].includes(containedString)) {
          sum += system[1];
        }
      },
    );
    return sum;
  }

  get productionSum(): number {
    const producerValues = producers.flatMap((t: EnergyCircleType) => this.getSystemKeys(this.deviceData, t)
      .map((key: string) => this.energyResponse?.energies?.[t]?.[key] ?? 0));
    return sum(producerValues);
  }

  get hasProducer(): boolean {
    return producers.some((t: EnergyCircleType) => this.isSystemPresent(this.deviceData, t));
  }

  async chartRangeChange(params: any) {
    this.start = params.start;
    this.end = params.end;
    this.period = params.period;

    const fetchEnergyDataFunction = this.isMpc ? this.fetchDataByPeriodMpc : this.fetchDataByPeriod;
    this.energyResponse = await fetchEnergyDataFunction({
      id: this.mpcId.replaceAll('_', '-'),
      start: this.start,
      end: this.end,
      period: this.period,
    });

    const scores: any = {};
    const scoresForDataExport: any = {};
    const pvProduction = `${this.$t('mlModel.EMS.charts.chartDifference.production')}`;
    const batteryFromProduction = `${this.$t('mlModel.EMS.charts.chartDifference.productionBattery')}`;
    const gridFromProduction = `${this.$t('mlModel.EMS.charts.chartDifference.productionGrid')}`;
    const directConsumption = `${this.$t('mlModel.EMS.charts.chartDifference.directConsumption')}`;
    const batteryProductionSum = Math.max(this.systemFilterSum('battery', 'from_production'), 0);
    const gridProductionSum = Math.max(this.systemFilterSum('grid', 'from_production'), 0);
    const directConsumptionValue = Object.keys(this.energyResponse.calculations).includes(
      'direct_consumption',
    )
      ? this.energyResponse.calculations.direct_consumption
      : Math.max(this.productionSum - batteryProductionSum - gridProductionSum, 0);

    if (this.hasProducer) {
      scores[pvProduction] = Math.max(this.productionSum, 0);
      scoresForDataExport[pvProduction] = { value: Math.max(this.productionSum, 0), unit: 'kWh' };
    }
    if (this.systemCount(EnergyCircleType.battery)) {
      scores[batteryFromProduction] = Math.max(batteryProductionSum, 0);
      scoresForDataExport[batteryFromProduction] = { value: Math.max(batteryProductionSum, 0), unit: 'kWh' };
    }
    if (this.systemCount(EnergyCircleType.grid)) {
      scores[gridFromProduction] = this.roundNumber(gridProductionSum);
      scoresForDataExport[gridFromProduction] = { value: this.roundNumber(gridProductionSum), unit: 'kWh' };
    }
    if (this.hasProducer) {
      scores[directConsumption] = this.roundNumber(directConsumptionValue);
      scoresForDataExport[directConsumption] = { value: this.roundNumber(directConsumptionValue), unit: 'kWh' };
    }
    this.scores = {
      ...scores,
      TotalConsumption: this.roundNumber(this.energyResponse.calculations.total_consumption),
      ...(this.systemCount(EnergyCircleType.pv) && this.systemCount(EnergyCircleType.grid) ? {
        SelfSufficientScore: this.roundNumber(this.energyResponse.calculations.autarchy_score),
        Eigenverbrauchsanteil: this.roundNumber(
          Object.keys(this.energyResponse.calculations).includes('self_consumption_score')
            ? this.energyResponse.calculations.self_consumption_score
            : this.energyResponse.calculations.self_sufficient_score,
        ),
      } : {}),
    };
    this.scoresForDataExport = scoresForDataExport;
    this.scoresForDataExport.TotalConsumption = { value: this.scores.TotalConsumption, unit: 'kWh' };
    this.scoresForDataExport.SelfSufficientScore = { value: this.scores.SelfSufficientScore, unit: '%' };
    this.scoresForDataExport.Eigenverbrauchsanteil = { value: this.scores.Eigenverbrauchsanteil, unit: '%' };
  }
}
